@import url('https://fonts.googleapis.com/css2?family=Rock+Salt&family=Raleway&display=swap');

@keyframes dieRoll {
  0% {
    transform: rotate(-55deg);
    animation-timing-function: cubic-bezier(0.91, 0, 0.9, 0.65);
    transform-origin: bottom left;
  }
  48% {
    transform: rotate(0deg);
    transform-origin: bottom left;
  }
  49% {
    transform: rotate(0deg);
    animation-timing-function: cubic-bezier(0.09, 0.46, 0.43, 0.99);
    transform-origin: bottom right;
  }
  79% {
    transform: rotate(16deg);
    transform-origin: bottom right;
  }
  80% {
    animation-timing-function: cubic-bezier(0.58, 0.01, 0.89, 0.47);
  }
  100% {
    transform: rotate(0deg);
    transform-origin: bottom right;
  }
}

@keyframes dieNumberPulse {
  0% {
    transform: scale(1.5);
  }
  100% {
    transform: scale(1);
  }
}

@keyframes die-char-switch {
  0% {
    content: attr(data-char-1);
  }
  50% {
    content: attr(data-char-2);
  }
  100% {
    content: attr(data-char-3);
  }
}

.die-1 {
  animation: dieRoll 0.8s forwards;

  transform: rotate(-55deg);
  transform-origin: bottom left;
}

.die-number-1 {
  animation: dieNumberPulse 0.24s 0.384s, dieNumberPulse 0.24s 0.74s;
  animation-timing-function: cubic-bezier(0.06, 0.56, 0.48, 0.99);
}

.die-number-1:after {
  content: attr(data-char-1);
  animation-timing-function: steps(1);
  animation: die-char-switch 1.2s forwards;
}

.die-2 {
  animation: dieRoll 0.8s 0.15s forwards;

  transform: rotate(-55deg);
  transform-origin: bottom left;
}

.die-number-2 {
  animation: dieNumberPulse 0.24s 0.534s, dieNumberPulse 0.24s 0.89s;
  animation-timing-function: cubic-bezier(0.06, 0.56, 0.48, 0.99);
}

.die-number-2:after {
  content: attr(data-char-1);
  animation-timing-function: steps(1);
  animation: die-char-switch 1.2s 0.15s forwards;
}

@keyframes modifierSqueezeIn {
  0% {
    border: 9px solid;
    transform: rotate(0deg) scale(0);
    animation-timing-function: cubic-bezier(0.02, 0.5, 0.29, 1);
  }
  33% {
    border: 5px solid;
    transform: rotate(-19deg) scale(1.25, 0.19);
  }
  50% {
    border: 7px solid;
  }
  58% {
    border: 5px solid;
    transform: rotate(20deg) scale(0.77, 1.24);
  }
  67% {
    border: 5px solid;
    /* transform: ; */
  }
  83% {
    border: 7px solid;
    transform: scale(1, 1);
  }
  100% {
    border: 6px solid;
    transform: rotate(0deg);
  }
}

@keyframes modifierNumberSqueezeIn {
  0% {
    transform: scale(1);
  }
  64% {
    transform: scale(0.7, 1.5);
  }
  100% {
    transform: scale(1);
    font-size: 1.4rem;
  }
}

.modifier-1 {
  animation: modifierSqueezeIn 0.4s 0.9s forwards;

  border: 0px solid;
  border-radius: 0.5rem;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modifier-number-1 {
  animation: modifierNumberSqueezeIn 0.467s 0.9s forwards;
  transform: scale(0);
}

.modifier-2 {
  animation: modifierSqueezeIn 0.4s 1.2s forwards;

  border: 0px solid;
  border-radius: 0.5rem;
  padding: 0 !important;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modifier-number-2 {
  animation: modifierNumberSqueezeIn 0.467s 1.2s forwards;
  transform: scale(0);
}

@keyframes rollResultMixedBeat {
  0% {
    transform: rotate3d(0, 1, 0, -269deg);
  }
  73% {
    transform: rotate3d(0, 1, 0, 31deg);
  }
  100% {
    transform: rotate3d(0, 1, 0, 0deg);
  }
}

.roll-result-mixed-beat {
  animation: rollResultMixedBeat 0.87s forwards;
  transform: rotate3d(0, 1, 0, -269deg);
}

@keyframes rollResultUpBeat {
  0% {
    transform: rotate(0deg) scale(1, 1);
  }
  20% {
    transform: rotate(-4deg) scale(0.9, 1.11);
  }
  40% {
    transform: rotate(-4deg) scale(1.11, 0.9);
  }
  50% {
    transform: rotate(4deg) scale(1.11, 0.9);
  }
  70% {
    transform: rotate(4deg) scale(0.9, 1.11);
  }
  90% {
    transform: rotate(4deg) scale(1.11, 0.9);
  }
  100% {
    transform: rotate(0deg) scale(1, 1);
  }
}

.roll-result-up-beat {
  animation: rollResultUpBeat 1.3s;
  animation-iteration-count: 1;
  transform-origin: bottom;
  animation-timing-function: ease-out;
}

@keyframes rollResultDownBeat {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-2px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(2px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-2px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(2px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}

.roll-result-down-beat {
  animation: rollResultDownBeat 0.35s;
  animation-iteration-count: 1;
}
